import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUi from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Request from "@/utils/http/request";

Vue.config.productionTip = false
Vue.use(ElementUi);

Vue.prototype.request = Request;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

// Date类型扩展方法format eg： new Date().format("yyyy-MM-dd hh:mm:ss");
if (!('format' in new Date())) {
    Date.prototype.format = function (format) {
        var date = this;
        var map = {
            "M": date.getMonth() + 1, // 月份
            "d": date.getDate(), // 日
            "H": date.getHours(), // 小时
            "m": date.getMinutes(), // 分钟
            "s": date.getSeconds(), // 秒
            "q": Math.floor((date.getMonth() + 3) / 3), // 季度
            "S": date.getMilliseconds() // 毫秒
        };
        format = format.replace(/([yMdHmsqS])+/g, function (all, t) {
            var v = map[t];
            if (v !== undefined) {
                if (all.length > 1) {
                    v = '0' + v;
                    v = v.substr(v.length - all.length);
                }
                return v;
            } else if (t === 'y') {
                return (date.getFullYear() + '').substr(4 - all.length);
            }
            return all;
        });
        return format;
    }
}

Vue.filter('date', function (value) {
    if (!value) return '';
    let date = new Date(value);
    if (!date instanceof Date || isNaN(date)) return '';
    return date.format("yyyy-MM-dd");
});
Vue.filter('datetime', function (value) {
    if (!value) return '';
    let date = new Date(value);
    if (!date instanceof Date || isNaN(date)) return '';
    return date.format("yyyy-MM-dd HH:mm:ss");
});