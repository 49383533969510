import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        permission: [],
        menus: [],
        adminInfo: {},
        activeMenu: '',
        activeMenuItem: {},
        operationId: null
    },
    getters: {
        permission: (state) => {
            return state.permission;
        },
        menus: (state) => {
            return state.menus;
        },
        adminInfo: (state) => {
            return state.adminInfo;
        },
        operationId: (state) => {
            return state.operationId;
        },
        activeMenu: (state) => {
            return state.activeMenu;
        },
        activeMenuItem: (state) => {
            return state.activeMenuItem;
        }
    },
    mutations: {
        setPermission(state, permission) {
            state.permission = permission;
        },
        setMenus(state, menus) {
            state.menus = menus;
        },
        setAdminInfo(state, adminInfo) {
            state.adminInfo = adminInfo;
        },
        setOperationId(state, operationId) {
            state.operationId = operationId;
        },
        setActiveMenu(state, activeMenu) {
            state.activeMenu = activeMenu;
        },
        setActiveMenuItem(state, activeMenuItem) {
            state.activeMenuItem = activeMenuItem;
        }
    },
    actions: {
        setPermission(context, permission) {
            context.commit('setPermission', permission);
        },
        setMenus(context, menus) {
            context.commit('setMenus', menus);
        },
        setAdminInfo(context, adminInfo) {
            context.commit('setAdminInfo', adminInfo);
        },
        setOperationId(context, operationId) {
            context.commit('setOperationId', operationId);
        },
        setActiveMenu(context, activeMenu) {
            context.commit('setActiveMenu', activeMenu);
        },
        setActiveMenuItem(context, activeMenuItem) {
            context.commit('setActiveMenuItem', activeMenuItem);
        }
    }
})
