import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('../views/layout/main.vue'),
        redirect: '/home',
        children: [
            {
                path: 'home',
                name: 'home',
                component: () => import('../views/home/index.vue'),
            },
            {
                name: 'site',
                path: 'site',
                component: () => import('../views/site/layout.vue'),
                children: [
                    {
                        path: 'banner',
                        name: 'siteBanner',
                        component: () => import('../views/site/banner.vue'),
                    },
                    {
                        path: 'recommend',
                        name: 'siteRecommend',
                        component: () => import('../views/site/recommend.vue'),
                    },
                    {
                        path: 'links',
                        name: 'siteLinks',
                        component: () => import('../views/site/links.vue'),
                    },
                    {
                        path: 'parameter',
                        name: 'siteParameter',
                        component: () => import('../views/site/parameter.vue'),
                    },
                    {
                        path: 'navigation',
                        name: 'siteNavigation',
                        component: () => import('../views/site/navigation.vue'),
                    },
                    {
                        path: 'navigation/category-:oid',
                        name: 'siteCategory',
                        component: () => import('../views/site/categories.vue'),
                    }
                ]
            },
            {
                path: 'program/:op/article',
                name: 'program',
                component: () => import('../views/program/article.vue'),
            },
            {
                path: 'operation/:op/article',
                name: 'operationArticle',
                component: () => import('../views/operation/article.vue'),
            },
            {
                path: 'operation/:op/category-:cid',
                name: 'operationCategory',
                component: () => import('../views/operation/article.vue'),
            },
            {
                path: 'program/:op/category',
                name: 'programCategory',
                component: () => import('../views/program/category.vue'),
            },
            {
                path: 'mailbox/:op/list',
                name: 'mailbox',
                component: () => import('../views/mailbox/list.vue'),
            },
            {
                path: 'mailbox/:op/note',
                name: 'mailboxNote',
                component: () => import('../views/mailbox/note.vue'),
            },
            {
                path: 'article',
                name: 'article',
                component: () => import('../views/article/list.vue'),
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/common/Login.vue'),
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
